import { FC } from 'react';
import logo from './Logo.png';
import { InvoiceResource } from '../../generated/BackendApiClient';

type FullInvoiceProps = {
    invoice: InvoiceResource
}

export const InvoiceHeader: FC<FullInvoiceProps> = ({ invoice }) => {

    return (
        <div className='invoice-header'>
            <div className='addresses'>
                <div className='address'>
                    <b>Black Forest Sentinel</b><br />
                    Marcel Krübel &amp; Ian Winter<br />
                    Im Hänferstück 42<br />
                    77855 Achern
                </div>
                <div className='address'>
                    <b>{invoice.customer.name}</b><br />
                    {invoice.customer.contactName ? invoice.customer.contactName : ''}
                    {invoice.customer.contactName && (<br />)}
                    {invoice.customer.streetName} {invoice.customer.houseNumber}<br />
                    {invoice.customer.postalCode} {invoice.customer.cityName}<br />
                </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
                <div>
                    <img className='logo' alt='blackforestsentinel-logo' src={logo}></img>
                </div>
                <div className='invoice-data'>
                    <table>
                        <tbody>
                            <tr>
                                <td><b>Rechnungsnummer:</b></td>
                                <td>{invoice.invoiceNumber}</td>
                            </tr>
                            <tr>
                                <td><b>Rechnungsdatum:</b></td>
                                <td>{new Date(invoice.invoiceDate).toLocaleDateString('de-DE')}</td>
                            </tr>
                            <tr>
                                <td><b>Fälligkeitsdatum:</b></td>
                                <td>{new Date(invoice.dueDate).toLocaleDateString('de-DE')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}