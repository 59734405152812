import { FC, useEffect, useRef } from "react"
import { useStatsQuery } from "../store/stats/StatsQueries";
import { Chart } from 'chart.js/auto';
import { Spinner } from "react-bootstrap";

const monthNames = [
    'Jan',
    'Feb',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'Aug',
    'Sept',
    'Okt',
    'Nov',
    'Dez'
]

export const Statistics: FC = () => {
    const { basicStatistics } = useStatsQuery();
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstanceRef = useRef<Chart<"bar", { x: number; y: number; }[], string> | null>(null);

    const chartData = Object.entries(basicStatistics?.costByMonth ?? {})
        .map(([month, revenue]) => ({
            x: new Date(month),
            y: revenue,
        }))
        .sort((a, b) => a.x.getTime() - b.x.getTime())
        .map(data => ({
            x: data.x,
            y: data.y,
        }));

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: chartData.map(d => d.x.toLocaleDateString('de-DE')),
                        datasets: [{
                            label: `Verdienst im Monat`,
                            data: chartData.map(d => ({ x: d.x.getTime(), y: d.y })),
                            borderColor: '#87a37a',
                            borderWidth: 1
                        }],
                    },
                    options: {
                        scales: {
                            x: {
                                labels: chartData.map(d => d.x.toLocaleDateString('de-DE')),
                                ticks: {
                                    callback: (i) => {
                                        const date = chartData[i as number].x
                                        return `${monthNames[date.getMonth()]} ${date.getFullYear()}`
                                    },
                                }
                            },
                            y: {
                                ticks: { callback: (v) => `${v}€` }
                            }
                        }
                    }
                });
            }
        }

        return () => chartInstanceRef?.current?.destroy()
    });

    return (
        <div className="w-full md:w-1/2">
            <h4 className="text-lg md:text-3xl">Umsatz durch einmalige Leistungen:</h4>
            <canvas ref={chartRef} className="w-full mb-6"></canvas>
            <h4 className="text-lg md:text-3xl">Monatlicher Umsatz: <b>{basicStatistics?.monthlyRepeatingRevenue ?? <Spinner />}€</b></h4>
        </div>
    )
}